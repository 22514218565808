<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="steps-wrap">
      <b-container>
        <b-row>
          <b-col>
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('orderPaymentMethodViewShoppingCart')"></el-step>
              <el-step :title="$t('orderPaymentMethodConfirmOrderInfo')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderSubmitSuccess')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderPaymentSuccess')"></el-step>
            </el-steps>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="con-wrap">
      <b-container>
        <b-row>
          <b-col>
            <div class="items-con">
              <div class="address-wrap">
                <div class="title">
                  <span>选择收货地址</span>
                  <span @click="openAddAddress">+添加收货地址</span>
                </div>
                <div class="addr-items">
                  <div v-if="allUserAddress.length > 0">
                    <el-radio-group v-model="addressId">
                      <el-radio :label="item.address_id" v-for="(item, index) in allUserAddress" :key="index">{{item.name}}&nbsp;{{item.phone}}&nbsp;{{item.province_name}}{{item.city_name}}{{item.area_name}}{{item.detail_address}}</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="no-addr" v-else>
                    <div class="pic">
                      <img src="@/../public/icon/mall/orderSettlement/d08.png" alt="addr-empty">
                    </div>
                    <span>亲，还没有地址哦</span>
                  </div>
                </div>
              </div>
              <div class="distribution-wrap">
                <div class="title">选择配送方式</div>
                <div class="btn-wrap">
                  <div :class="distributionMode == 1 ? 'button active' : 'button'" @click="changeDistributionMode(1)">平台配送</div>
                  <div :class="distributionMode == 2 ? 'button active' : 'button'" @click="changeDistributionMode(2)">商家配送</div>
                </div>
              </div>
              <div class="confirm-order-info">
                <div class="title">确认商品信息</div>
                <div class="order-goods-wrap">
                  <div class="item-title-wrap">
                    <div class="item">商品</div>
                    <div class="item">单价(元)</div>
                    <div class="item">数量</div>
                    <div class="item">小计</div>
                  </div>
                  <div class="items-wrap">
                    <div class="item-con" v-for="(item, index) in orderGoodsItems" :key="index">
                      <div class="item">
                        <div class="goods-info">
                          <div class="pic">
                            <img :src="item.img_path" :alt="item.goods_name">
                          </div>
                          <div class="goods-detail">
                            <p>{{item.goods_name}}</p>
                            <p>{{item.goods_parameter}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="goods-price">￥{{item.unit_price}}</div>
                      </div>
                      <div class="item">
                        <div class="goods-quantity">{{item.goods_num}}</div>
                      </div>
                      <div class="item">
                        <div class="subtotal">￥{{item.total_price}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="buyer-form">
                <el-form ref="form" :model="form" label-width="110px">
                  <el-form-item label="买家留言：">
                    <el-input type="textarea" v-model="form.msg"></el-input>
                  </el-form-item>
                  <el-form-item label="开发票：">
                    <!-- 发票类型 0不开发票 1个人 2企业 -->
                    <el-radio-group v-model="form.invoiceType" @change="changeInvoiceType">
                      <el-radio :label="1">个人</el-radio>
                      <el-radio :label="2">企业</el-radio>
                    </el-radio-group>
                    <span class="invoice-tip">(不勾选默认不开发票)</span>
                  </el-form-item>
                  <el-form-item label="发票抬头：">
                    <el-input type="text" v-model="form.invoiceHeader"></el-input>
                  </el-form-item>
                  <el-form-item label="纳税人识别号：" v-if="form.invoiceType == 2">
                    <el-input type="text" v-model="form.taxpayerId"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="splitLine"></div>
      <b-container>
        <b-row>
          <b-col>
            <div class="submit-wrap">
              <div class="submit-tip">注：预付定金下单需预付50%定金，产品发货前需再付50%尾款。</div>
              <div class="submit-btn-wrap">
                <div class="total-price">应付总额：<span>￥{{totalPrice}}</span></div>
                <div class="submit-btn first" @click="orderSubmit">预付定金</div>
                <div class="submit-btn second" @click="orderSubmit">提交订单</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <footer-nav></footer-nav>
    <el-dialog
      title="添加收货地址"
      :visible.sync="openDialog"
      width="30%"
      center>
      <el-form :model="dialogForm" :rules="rules" size="small" label-width="90px">
        <el-form-item label="选择地址：">
          <li-area-select v-model="defultAddress"></li-area-select>
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-input v-model="dialogForm.detailAddr"></el-input>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="dialogForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="userPhone">
          <el-input v-model="dialogForm.userPhone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import liAreaSelect from "@/components/liAreaSelect";
  import regExp from "@/util/regExp";
  import dump from "@/util/dump";
  export default {
    components: {
      headNav,
      footerNav,
      liAreaSelect
    },
    data(){
      return {
        active: 2,
        form: {
          invoiceHeader: '',
          taxpayerId: ''
        },
        token: null,
        openDialog: false,
        dialogForm: {},
        //默认值设置，可为空
        defultAddress: {
          sheng: '',
          shi: '',
          qu: '',
        },
        allUserAddress: [],
        // 配送方式 1平台配送 2商家配送
        distributionMode: 1,
        orderId: "",
        orderGoodsItems: [],
        // 商品总价
        totalPrice: 0,
        rules: {
          userPhone: [
            { validator: this.validateUserPhone, trigger: 'blur'}
          ]
        },
        validatorResult:{
          userPhone: false
        },
        addressId: ""
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.orderId = this.$route.query.orderid;
        this.getAllUserAddress();
        this.getSubmitOrderPage();
      },
      // 获取用户所有地址
      getAllUserAddress(){
        this.$api.allUserAddress({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            res.data.forEach((item, index)=> {
              if(item.is_default){
                this.addressId = item.address_id;
              }else if(!index){
                this.addressId = item.address_id;
              }
            })
            this.allUserAddress = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 切换配送方式
      changeDistributionMode(val){
        this.distributionMode = val;
      },
      // 获取结算商品信息
      getSubmitOrderPage(){
        this.$api.submitOrderPage({
          order_id: this.orderId,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.orderGoodsItems = res.data.childs;
            this.totalPrice = res.data.total_price;
          }
        }).catch(err => console.error(err));
      },
      // 切换发票类型
      changeInvoiceType(val){
        this.form.invoiceType = val;
      },
      // 开启添加地址窗口
      openAddAddress(){
        this.openDialog = !this.openDialog;
      },
      // 验证用户手机号
      validateUserPhone(){
        let val = arguments[1] || '';
        if (!val.trim()){
          this.validatorResult.userPhone = false;
          return arguments[2](new Error('手机号不能为空'));
        }
        if(!regExp.phone.test(val.trim())){
          this.validatorResult.userPhone = false;
          return arguments[2](new Error('请输入正确的手机号'));
        } 
        this.validatorResult.userPhone = true;
      },
      // 保存地址
      saveAddress(){
        
        if(!this.defultAddress.sheng){
          return this.$message.error('请选择您所在的省份');
        }
        
        if(!this.defultAddress.shi){
          return this.$message.error('请选择您所在的城市');
        }
        
        if(!this.defultAddress.qu){
          return this.$message.error('请选择您所在城市的区域');
        }
        
        if(!this.dialogForm.detailAddr){
          return this.$message.error('请输入您的详细地址');
        }
        
        if(!this.dialogForm.userName){
          return this.$message.error('请输入收货人姓名');
        }
        
        if(!this.validatorResult.userPhone){
          return this.$message.error('请输入收货人手机号码');
        }
        
        this.$api.saveUserAddress({
          province_id: this.defultAddress.sheng.split(",")[1],
          city_id: this.defultAddress.shi.split(",")[1],
          area_id: this.defultAddress.qu.split(",")[1],
          detail_address: this.dialogForm.detailAddr.trim(),
          name: this.dialogForm.userName.trim(),
          phone: this.dialogForm.userPhone.trim(),
        }).then(res => {
          console.log(res)
          if(res.status == 100){
            this.openAddAddress();
            this.getAllUserAddress();
            this.$message({
              message: '地址添加成功',
              type: 'success'
            });
          }
          if(res.status == 103){
            dump.link({
              type: 1,
              link: "/login"
            });
          }
        }).catch(err => console.error(err))
      },
      // 提交订单
      orderSubmit(){
        if((this.form.invoiceType == 1 || this.form.invoiceType == 2) && !this.form.invoiceHeader.trim()){
          return this.$message.error('请输入发票抬头');
        }
        if(this.form.invoiceType == 2 && !this.form.taxpayerId.trim()){
          return this.$message.error('请输入纳税人识别号');
        }
        if(this.addressId == ''){
          return this.$message.error('请添加收货地址');
        }
        
        this.$api.userSubmitOrder({
          order_id: this.orderId,
          address_id: this.addressId,
          distribution_mode: this.distributionMode,
          leaving_message: this.form.msg || '',
          is_invoice: this.form.invoiceType || 0,
          invoices_rise: this.form.invoiceHeader.trim(),
          taxpayer_number: this.form.taxpayerId.trim(),
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.$message({
              message: '下单成功',
              type: 'success'
            });
            localStorage.setItem("order_id",res.data);
            localStorage.setItem("price", this.totalPrice);
            location.href="/#/pay/orderPaymentMethod";
          }
        }).catch(err => console.error(err))
      }
      
    }
  }
</script>

<style lang="scss" scoped>
  // @import "@/assets/scss/style";
  @import "@/view/mall/orderSettlement/orderSettlement";
</style>
